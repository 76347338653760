import { getSrc } from 'gatsby-plugin-image';

function getCover(node) {
  if (node.cover) {
    return node.cover;
  }

  return node.socialImage ? getSrc(node.socialImage) : getSrc(node.image);
}

export const combinePosts = (...posts) => {
  return [].concat(...posts).sort((a, b) => {
    if (a.createdAt < b.createdAt) {
      return 1;
    }

    if (a.createdAt > b.createdAt) {
      return -1;
    }

    return 0;
  }).map((post) => ({
    ...post,
    text: post.excerpt,
    cover: getCover(post),
  }));
};
