import { graphql, useStaticQuery } from 'gatsby';

export const useNessyQuery = () => {
  const { allFeedNessyBlog: { edges } } = useStaticQuery(graphql`
    query NessyQuery {
      allFeedNessyBlog {
        edges {
          node {
            title
            link
            id
            creator
            contentSnippet
            date: isoDate(formatString: "DD [de]  MMMM, YYYY", locale: "es")
            createdAt: isoDate
            media {
              content {
                attrs {
                  url
                  medium
                }
              }
            }
          }
        }
      }
    }
  `);

  return edges
    .filter(({ node }) => node.creator === 'eli')
    .map(({ node }) => ({
      title: node.title,
      url: node.link,
      excerpt: node.contentSnippet,
      date: node.date,
      createdAt: node.createdAt,
      cover: node.media.content[0].attrs.url
    }));
};
