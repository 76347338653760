import * as React from "react"
import { Link as GatsbyLink } from "gatsby"
import { Card } from 'gatsby-theme-mate/src/components/Card';
import { Heading, Text } from 'rebass/styled-components';
import styled from 'styled-components';
import ImageLabel from 'gatsby-theme-mate/src/components/ImageLabel';

const Link = ({ slug, url, children }) => {
  if (slug) {
    return (
      <GatsbyLink style={{ textDecoration: "none" }} to={slug}>
        {children}
      </GatsbyLink>
    )
  }

  return (
    <a
      href={url}
      target="_blank"
      style={{ textDecoration: 'none' }}
    >
      {children}
    </a>
  );
}

const PostLink = ({ title, slug, date, text, cover, url }) => (
  <Link style={{ textDecoration: "none" }} to={slug} url={url}>
    <Card p={0} pb={4}>
      <EllipsisHeading m={3} color="text">
        {title}
      </EllipsisHeading>
      {cover && <CoverImage src={cover} height="200px" alt={title} />}
      <Text m={3} color="text">
        {text}
      </Text>
      <ImageLabel bg="primary" color="background" position="bottom-right" round>
        {date}
      </ImageLabel>
    </Card>
  </Link>
)

const CoverImage = styled.img`
  width: 100%;
  object-fit: cover;
`;

const EllipsisHeading = styled(Heading)`
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-inline-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
`;

export default PostLink
