import React from 'react';
import Headroom from 'react-headroom';
import { Box, Flex, Image } from 'rebass/styled-components';
import styled from 'styled-components';
import { useHelmetQuery } from 'gatsby-theme-mate/src/queries/useHelmetQuery';
import { SECTION } from 'gatsby-theme-mate/src/utils/constants';
import { Link as GatsbyLink } from "gatsby"
import HeaderLink from '../../components/header-link';

const Header = (props) => {
  const { profile } = useHelmetQuery();

  return (
    <StyledHeadroom {...props}>
      <Flex
        flexWrap="wrap"
        justifyContent="space-between"
        alignItems="center"
        px={3}
      >
        <GatsbyLink to={`/`}>
          <Flex justifyContent="center">
            <Image
              src={profile.bigIcon.src}
              height={['60px', '80px']}
              width={['60px', '80px']}
              alt="Portfolio Logo"
              p={2}
              css={{ borderRadius: '20px', cursor: 'pointer' }}
            />
          </Flex>
        </GatsbyLink>
        <Flex mr={[0, 3, 5]}>
          {Object.keys(SECTION)
          .filter((id) => id !== 'home')
          .map((id) => (
            <Box key={id} ml={[2, 3]} color="background" fontSize={[2, 3]}>
              {id === 'writing' ? (
                <HeaderLink to="blog">
                  {SECTION[id]}
                </HeaderLink>
              ) : (
                <HeaderLink to={`/#${id}`} tabIndex={0}>
                  {SECTION[id]}
                </HeaderLink>
              )}
            </Box>
          ))}
        </Flex>
      </Flex>
    </StyledHeadroom>
  );
};

const StyledHeadroom = styled(Headroom)`
  * {
    transition: background-color 0.1s ease;
  }
  .headroom--pinned {
    background-color: ${({ theme }) => theme.colors.primary};
  }
  background-color: ${({ theme, initialColor }) => initialColor ? theme.colors.primary : 'transparent'};
  position: absolute;
  width: 100%;
`;

export default Header;
