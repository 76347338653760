import { graphql, useStaticQuery } from 'gatsby';
import { MEDIUM_CDN, MEDIUM_URL } from 'gatsby-theme-mate/src/utils/constants';

const EMPTY_RESPONSE = { author: null, posts: [] };

export const useMediumQuery = () => {
  const { mediumUser } = useStaticQuery(graphql`
    query MediumQuery {
      mediumUser {
        id
        name
        username
        posts {
          id
          uniqueSlug
          title
          date: createdAt(formatString: "DD [de]  MMMM, YYYY", locale: "es")
          createdAt
          virtuals {
            subtitle
            readingTime
            previewImage {
              imageId
            }
          }
        }
      }
    }
  `);

  const { posts: rawPosts, ...author } = mediumUser;

  if (author.username === '@medium') return EMPTY_RESPONSE;

  const posts = rawPosts.map((p) => ({
    title: p.title,
    excerpt: p.virtuals.subtitle,
    cover: `${MEDIUM_CDN}/${p.virtuals.previewImage.imageId}`,
    url: `${MEDIUM_URL}/@${mediumUser.username}/${p.uniqueSlug}`,
    date: p.date,
    createdAt: p.createdAt,
    time: p.virtuals.readingTime,
  }));

  return {
    posts,
    author,
  };
};
